html {
    position: relative;
    min-height: 100%;
}

body {
    font-family: 'ff-meta-web-pro-condensed', sans-serif;
    background: rgba(0, 0, 0, 0) url(/img/bg1.jpg) no-repeat fixed center center / cover;
}

b {
    color:#955bed;
}

/*************
 * Alignment *
 *************/

.center-block {
    margin-left: auto;
    margin-right: auto;
}

/***********
 * Margins *
 ***********/
.margin-top-0 {
    margin-top: 0;
}

.margin-top-75 {
    margin-top: 75px;
}

.margin-top-12 {
    margin-top: 12px;
}

.margin-bottom-12 {
    margin-bottom: 12px;
}

.margin-top-15 {
    margin-top: 15px;
}

.margin-top-17 {
    margin-top: 17px;
}

.margin-bottom-17 {
    margin-bottom: 17px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.margin-top-20 {
    margin-top: 20px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-bottom-30 {
    margin-bottom: 30px;
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.margin-sides-10 {
    margin-left: 10px;
    margin-right: 10px;
}

.margin-top-80 {
    margin-top: 80px;
}

.margin-top-100 {
    margin-top: 100px;
}

.panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 19px;
    color: inherit;
}
.margin-right-15 {
    margin-right: 15px;
}
.w-100 {
    width: 100px;
}
/**********
 * Sizing *
 **********/

.full-width {
    width: 100% !important;
}

.text-center {
    text-align: center !important;
}

/***********
 * Borders *
 ***********/

.border-right-grey {
    border-right: solid 1px #bbbbbb;
}

/****************
 * Text Styling *
 ****************/


.white-font {
    color: #818181;
}

.white-font2 {
    color: #ffffff;
}

.font-sans {
    font-family: "fira-sans-2", sans-serif !important;
}

.font-weight-600 {
    font-weight: 600;
}


a {
    color: #955bed;
    text-decoration: none;
}

a:focus, a:hover {
    color: rgba(163, 163, 163, 1.00);
    text-decoration: none;
}
.uppercase {
    text-transform: uppercase;
}

.text-white, .text-white * {
    color: #fff;
}

.panel-title a:hover {
    color:#fff;
}

/**********************
 * Styling Prevention *
 **********************/

.prevent-read-only.form-control[readonly] {
    background-color: white !important;
}

/************
 * Loader *
 ************/

.loading {
    position: relative;
    min-height: 200px;
    height: 100%;
}

.loading span {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 1);
    background-image: url('/img/loading.gif');
    background-repeat: no-repeat;
    background-position: center center;
    text-align: center;
    z-index: 1000;
    min-height: 100px;
}

/**********
 * Cursor *
 **********/

a, .pointer {
    cursor: pointer;
}

/**********
 * Forms *
 **********/

.ng-submitted .ng-invalid {
    border-color: #FA787E;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(250, 120, 126, 0.6);
}

/**********
 * Footer *
 **********/

footer {
    background-color: rgba(58, 58, 58, 1);
    opacity: 0.88;
    padding: 30px 0 20px;
    overflow: hidden;
}

/**********
 * Nav Bar *
 **********/

.nav-bar {
    padding: 0 10px;
    border-radius: 2px;

    text-align: center;
}



.nav-bar a {
    color: #fff;
}

.nav-bar .nav-pills > li > a {
    border-radius: 0;
}

.nav-bar .nav > li > a:hover {
    color: #222;
}

.nav-bar .nav > li.active > a:hover {
    color: #fff;
}

.nav > li > a {
    position: relative;
    display: block;
    padding: 12px 20px 20px 20px;
    font-size: 13px;
    letter-spacing: 0.1em;
    color: #fff;
}

.form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
    font-family: "fira-sans-2", sans-serif !important;
    font-weight: 600;
}

div.dataTables_info {
    padding-top: 8px;
    white-space: nowrap;
    font-family: "fira-sans-2", sans-serif !important;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
}

.pagination > li {
    font-family: "fira-sans-2", sans-serif !important;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 10px;
}

.form-control.btn.btn-primary {
    padding: 20px 20px 40px 20px;
}

.bg-black-top-menu {
    background-color:#252525;
    background-image: url(/img/menu-top.png);
    background-repeat: no-repeat;
    background-position: bottom center;
}

/************
* Tables *
*********/

.table-striped > tbody > tr:nth-of-type(2n+1) {
    background-color: rgba(0, 0, 0, 0.2);
}

.panel {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 2px;
    color: #fff;
    margin-bottom: 6px;
    border: 0;
    background-repeat: no-repeat;
    background-size: cover;
}

.panel-heading {
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    padding: 10px 15px;
}

.panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 19px;
    color: inherit;
    font-family: "fira-sans-2", sans-serif !important;
    font-weight: 600;
}

.form-group {
    padding-top: 10px;
}

.panel-body {
    padding: 35px;
}

.border-style1 {
    border-bottom: 1px solid #f7f7f7;
    padding-bottom: 10px;
}

.labels-style {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
}

.panel-default > .panel-heading {
    border-color: #616161;
    color: #fff;
    border-radius: 0;
    height: 60px;
    padding-top: 22px;
    padding-left: 20px;
    text-transform: uppercase;
    padding-right: 20px;
    background: rgb(149, 91, 237); /* Old browsers */
    background: -moz-linear-gradient(top, rgba(149, 91, 237, 1) 0%, rgba(133, 78, 201, 1) 9%, rgba(166, 124, 234, 1) 93%, rgba(134, 72, 234, 1) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(149, 91, 237, 1)), color-stop(9%, rgba(133, 78, 201, 1)), color-stop(93%, rgba(166, 124, 234, 1)), color-stop(100%, rgba(134, 72, 234, 1))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(149, 91, 237, 1) 0%, rgba(133, 78, 201, 1) 9%, rgba(166, 124, 234, 1) 93%, rgba(134, 72, 234, 1) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(149, 91, 237, 1) 0%, rgba(133, 78, 201, 1) 9%, rgba(166, 124, 234, 1) 93%, rgba(134, 72, 234, 1) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(149, 91, 237, 1) 0%, rgba(133, 78, 201, 1) 9%, rgba(166, 124, 234, 1) 93%, rgba(134, 72, 234, 1) 100%); /* IE10+ */
    background: linear-gradient(to bottom, rgba(149, 91, 237, 1) 0%, rgba(133, 78, 201, 1) 9%, rgba(166, 124, 234, 1) 93%, rgba(134, 72, 234, 1) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#955bed', endColorstr='#8648ea', GradientType=0); /* IE6-8 */

}

.panel-default {
    border-color: transparent;
}

.nav-bar .nav-pills > li.active > a, .nav-bar .nav-pills > li.active > a:focus, .nav-bar .nav-pills > li.active > a:hover {
    background-color: #955bed;
    color: #fff;
}

.table.dataTable tbody tr.active td,
.table.dataTable tbody tr.active th {
    background-color: #955bed;
}

.table.dataTable tbody tr.active:hover td,
.table.dataTable tbody tr.active:hover th {
    background-color: rgba(149, 91, 237, 0.7) !important;
}

.table-striped.dataTable tbody tr.active:nth-child(odd) td,
.table-striped.dataTable tbody tr.active:nth-child(odd) th {
    background-color: #955bed;
}

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
    background-color: #955bed;
    border-color: #955bed;
}

.dataTables_filter, #customer-table_length {
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}


.font-purple {
    color: #CFB4F7;
    font-weight: 600;
}

.font-purple2 {
    color: rgba(32, 153, 217, 1.00);
}

.font-purple3 {
    color: #CFB4F7;
}

.table > tbody > tr > td, .table > tbody > tr > th, .table > tfoot > tr > td, .table > tfoot > tr > th, .table > thead > tr > td, .table > thead > tr > th {
    font-family: "fira-sans-2", sans-serif !important;
    font-weight: 600;
}

.form-control {
    font-family: "fira-sans-2", sans-serif !important;
    font-weight: 600;
}

.noUi-connect {
    background: #955bed;
    box-shadow: inset 0 0 3px rgba(51, 51, 51, .45);
    -webkit-transition: background 450ms;
    transition: background 450ms;
}

/************
* Buttons *
*********/



.btn-primary {
    border-color: #823CCD;
    color: #fff;
    outline: none !important;
    background: rgb(149, 91, 237); /* Old browsers */
    background: rgb(149, 91, 237); /* Old browsers */
    background: -moz-linear-gradient(top, rgba(149, 91, 237, 1) 0%, rgba(133, 78, 201, 1) 9%, rgba(166, 124, 234, 1) 88%, rgba(134, 72, 234, 1) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(149, 91, 237, 1)), color-stop(9%, rgba(133, 78, 201, 1)), color-stop(88%, rgba(166, 124, 234, 1)), color-stop(100%, rgba(134, 72, 234, 1))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(149, 91, 237, 1) 0%, rgba(133, 78, 201, 1) 9%, rgba(166, 124, 234, 1) 88%, rgba(134, 72, 234, 1) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(149, 91, 237, 1) 0%, rgba(133, 78, 201, 1) 9%, rgba(166, 124, 234, 1) 88%, rgba(134, 72, 234, 1) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(149, 91, 237, 1) 0%, rgba(133, 78, 201, 1) 9%, rgba(166, 124, 234, 1) 88%, rgba(134, 72, 234, 1) 100%); /* IE10+ */
    background: linear-gradient(to bottom, rgba(149, 91, 237, 1) 0%, rgba(133, 78, 201, 1) 9%, rgba(166, 124, 234, 1) 88%, rgba(134, 72, 234, 1) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#955bed', endColorstr='#8648ea', GradientType=0); /* IE6-9 */
}

.btn-primary.active, .btn-primary.focus, .btn-primary:active, .btn-primary:focus, .btn-primary:hover, .open > .dropdown-toggle.btn-primary {
    background-color: rgba(43, 43, 43, 1.00);
    border-color: #252525;
    color: #fff;
}

.btn {
    padding: 15px 12px;
}
.bc-button {
    border-radius:5px;
    padding:50px;
}
.bc-button i {
    font-size:36px;
}

.bc-button:hover, .bc-button.active {
    background-color:#955bed;
    border-color:#955bed;
    color:#fff;
}

.bc-button.program {
    padding:30px 0;
}


.product-row {
    margin-bottom:10px;
}

.product-icon {
    border-radius:5px;
    padding:40px;
    background-color:#fff;
    background-repeat:no-repeat;
    background-position:center;
    background-size:contain;
}

.product-icon.hot-tub {
    background-image:url(/img/icon-hottub.png);

}
.product-icon.pool {
    background-image:url(/img/icon-pool.png);
}

/*.fp-tableCell {*/
    /*display: table-cell;*/
    /*vertical-align: middle;*/
    /*width: 100%;*/
    /*height: 100%;*/
/*}*/

/*.fp-section.fp-table, .fp-slide.fp-table {*/
    /*display: table;*/
    /*table-layout: fixed;*/
    /*width: 100%;*/
    /*height: 600px;*/
/*}*/




.navbar-default .navbar-collapse, .navbar-default .navbar-form {
    border-color: transparent;
}

.navbar-default {
    background-color: transparent;
    border-color: transparent;
}

.nav > li > a.active {
    background-color: #955bed;
    color: #fff;
}

.container-fluid > .navbar-collapse, .container-fluid > .navbar-header, .container > .navbar-collapse, .container > .navbar-header {
    margin: 0 auto;
    padding-left: 390px;
}


.navbar-toggle .icon-bar {

    color: #fff;
    background-color: #fff;
}

.navbar-toggle {
    position: relative;
    float: right;
    padding: 9px 10px;
    margin-top: 8px;
    margin-right: -10px;
    margin-bottom: 8px;
    background-color: rgba(255, 255, 255, 0.13);
    border: 1px solid rgba(255, 255, 255, 0.99);
    border-radius: 1px;
    color: #fff;
}

.nav > li > a:focus, .nav > li > a:hover {
    text-decoration: none;
    background-color: #eee;
    color: #252525;
}


.customer-table-header {
    padding-bottom: 5px;
    border-bottom: solid 2px #bbb;
}

.customer-table-header .sort-link:hover {
    text-decoration: underline;
}

.customer-table-row {
    padding: 5px 0;
    font-size: 16px;
}

.customer-table-row:nth-child(odd) {
    background-color: #fff;
}

.customer-table-row:nth-child(even) {
    background-color: #ddd;
}

.customer-table-row .customer-action {
    width: 24px;
    cursor: pointer;
    display: none;
}

.customer-table-row:hover .customer-action {
    display: inline-block;
}

#customer-table tbody tr {
    cursor: pointer;
}

/************
* Footer *
*********/
.footer ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

ul.footer-area-retail li {
    padding-top: 10px;
    float: left;
    padding-right: 20px;

}

a.footer-hover {
    color: #939393;
}
footer a {
    display: block;
    width: 60px;
}

#footer-left span {
    line-height: 1em;
}

#fl-cc {
    font-size:1.6em;
    color:#EBEBEB;
    font-weight:300;
}

#footer-right {
    margin-top:10px;
    float:right;
}

#fr-name {
    display:inline-block;
}

#fr-emc {
    margin-top:20px;
    margin-right:15px;
    display:inline-block;
}

#fr-bc {
    margin-right:15px;
    display:inline-block;
}

/************
* Drop Box *
*********/
.drop-box {
    border:1px dashed #fff;
    padding:20px;
    background-color:#222;
    color:#fff;
}
.drop-box.dragover {
    border:1px dashed #222;
    background-color:#fff;
    color:#222;
}
/************
* Responsive *
*********/

@media only screen and (min-width: 320px) {
    .bg-black-top-menu {

        background: none;
        background-color: #252525;
        background-repeat: no-repeat;
        background-position: center;
    }

    .container-fluid > .navbar-collapse, .container-fluid > .navbar-header, .container > .navbar-collapse, .container > .navbar-header {
        margin: 0 auto;
        padding-left: 0;
    }

    .nav > li > a {
        position: relative;
        display: block;
        padding: 15px 20px 15px 40px;
        font-size: 13px;
        letter-spacing: 0.1em;
        color: #fff;
    }

    .panel-default {
        border-color: transparent;
        margin-top: 8px;
    }

    .panel-body {
        padding: 15px;
    }


    #fr-name {
        width:100%;
    }

    #footer-left {
        font-size: 10px;
        margin-top:15px;
        margin-bottom:0;
    }

    #fl-cc {
        padding-top:10px;
        display:block;
    }
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
    .bg-black-top-menu {

        background: none;
        background-color: #252525;
        background-repeat: no-repeat;
        background-position: center;
    }

    .container-fluid > .navbar-collapse, .container-fluid > .navbar-header, .container > .navbar-collapse, .container > .navbar-header {
        margin: 0 auto;
    }

    .nav > li > a {
        position: relative;
        display: block;
        padding: 12px 20px 20px 20px;
        font-size: 13px;
        letter-spacing: 0.1em;
        color: #fff;
    }

    .panel-default {
        border-color: transparent;
        margin-top: 8px;
    }

    .panel-body {
        padding: 15px;
    }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {

    body {
        margin-bottom: 100px;
    }

    .footer {
        position: absolute;
        bottom: 0;
        width: 100%;
    }

    .nav > li {
        position: relative;
        display: inline-block;
    }

    #footer-left {
        margin-left:20px;
    }

    .nav-bar .nav-pills > li.active > a, .nav-bar .nav-pills > li.active > a:focus, .nav-bar .nav-pills > li.active > a:hover {
        width: 100%;
    }

    .container-fluid > .navbar-collapse, .container-fluid > .navbar-header, .container > .navbar-collapse, .container > .navbar-header {
        margin: 0 auto;

    }

    .bg-black-top-menu {

        background: none;
        background-color: #252525;
        background-repeat: no-repeat;
        background-position: center;
    }

    .panel-default {
        border-color: transparent;
        margin-top: 0;
    }

    .nav > li > a {
        position: relative;
        display: block;
        padding: 12px 18px 20px 18px;
        font-size: 12px;
        letter-spacing: 0.1em;
        color: #fff;
    }

    .panel-body {
        padding: 25px;
    }

    .navbar-nav > li {
        float: none;
    }

    .navbar-nav {
        float: none;
        text-align: center;
    }

    #customer-table_filter:before {
        content: "\f002";
        font-family: FontAwesome;
        font-style: normal;
        font-weight: normal;
        text-decoration: inherit;
        color: #f7f7f7;
        /*bottom: 10px;*/
        /*left: 310px;*/
        /*position: absolute;*/
        z-index: 3;

    }
    #fr-name {
        width:178px;
    }
    #fr-name {
        margin-top:30px;
        margin-right:15px;
    }

    #content.login {
        position:absolute;
        top:50%;
        left:0;
        right:0;
        margin-top:-250px;
    }

}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
    .container-fluid > .navbar-collapse,
    .container-fluid > .navbar-header, .container > .navbar-collapse, .container > .navbar-header {
        margin: 0 auto;
    }

    .bg-black-top-menu {

        /*background-color:#252525;*/
        background-image: url(/img/menu-top.png);
        background-repeat: no-repeat;
        background-position: bottom center;
    }

    .panel-default {
        border-color: transparent;
        margin-top: 0;
    }

    .panel-body {
        padding: 35px;
    }

    #footer-right {
        font-size:12px;
    }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
    .container-fluid > .navbar-collapse, .container-fluid > .navbar-header, .container > .navbar-collapse, .container > .navbar-header {
        margin: 0 auto;
    }

    #fr-name {
        width:auto;
    }

    #footer-left {
        font-size: 14px;
    }

    #fl-cc {
        padding-top:0;
        display:block;
    }

}

/*==========  Non-Mobile First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
    #footer-right {
        font-size: 10px;
    }

    /*.bc-logo-btm {*/
        /*display: none;*/
    /*}*/

}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {

}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
    /*.nav > li {*/
        /*position: relative;*/
        /*display: block;*/
        /*width: 100%;*/
    /*}*/



}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {






    /*#customer-table_wrapper .row {*/
        /*margin-left:0;*/
        /*margin-right:0;*/
    /*}*/
}

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {

}

/* Button spacing */
.btn-space {
    margin-right: 5px;
}

.spinlab-img {
    text-align:center;
}